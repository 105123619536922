/*
 * @Auth: linjituan
 * @Date: 2021-11-11 10:32:55
 * @LastEditors: linjituan
 * @LastEditTime: 2021-12-01 11:13:18
 */
import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询设备位置数据列表
export function listDeviceLocation(query) {
  return request({
    url: '/deviceLocation/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询设备位置数据详细
export function getDeviceLocation(id) {
  return request({
    url: '/deviceLocation/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增设备位置数据
export function addDeviceLocation(data) {
  return request({
    url: '/deviceLocation',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改设备位置数据
export function updateDeviceLocation(data) {
  return request({
    url: '/deviceLocation',
    method: 'put',
    baseURL,
    data: data
  })
}

// 删除设备位置数据
export function delDeviceLocation(id) {
  return request({
    url: '/deviceLocation/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出设备位置数据
export function exportDeviceLocation(query) {
  return request({
    url: '/deviceLocation/export',
    method: 'get',
    baseURL,
    params: query
  })
}

// 获取设备位置报警事件信息
export function alarmDeviceLocation(query) {
  return request({
    url: '/deviceLocation/alarm',
    method: 'get',
    baseURL,
    params: query
  })
}

// 设备历史轨迹坐标查询(最多可查48小时)
export function queryHistoryCoordinates(query) {
  return request({
    url: '/deviceLocation/queryHistoryCoordinates',
    method: 'get',
    baseURL,
    params: query
  })
}

// 获取设备位置状态信息
export function statusDeviceLocation(query) {
  return request({
    url: '/deviceLocation/status',
    method: 'get',
    baseURL,
    params: query
  })
}
